header {
  width: var(--main-width);
  height: 120px;
  margin: 0 auto;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
header input {
  width: 23px;
  height: 23px;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  position: relative;
}
header input::before {
  content: "\f805";
  position: absolute;
  top: 0;
  left: 0;
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  color: rgb(138, 138, 138);
  font-size: 1.2em;
}
header p {
  font-size: 1.2em;
}
nav {
  position: fixed;
  top: 50%;
  left: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  width: 250px;
  padding-top: 60px;
  background: #181818;
  border-radius: 10px;
  box-shadow: inset 0 0 50px rgba(230, 230, 230, 0.041);
  transform: translate(-50%, -50%) scale(0);
  z-index: 1000;
  padding-bottom: 5px;
  transition: transform 0.4s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  border: 2px solid rgba(255, 255, 255, 0.151);
}
header input:checked::before {
  display: none;
}
header input:checked ~ nav {
  transform: translate(-50%, -50%) scale(1);
}
nav a {
  text-decoration: none;
  font-size: 0.8em;
  margin: 0.5em 0;
}
nav a .linkBtn {
  background: none;
  color: rgba(255, 255, 255, 0.61);
  border: none;
  cursor: pointer;
}
nav::before {
  content: "";
  width: 100%;
  height: 22%;
  position: absolute;
  border-radius: 10px 10px 0 0;
  top: 0;
  background: rgba(255, 255, 255, 0.021);
  box-shadow: inset 0 0 50px rgba(23, 77, 255, 0.041);
  z-index: 20;
}
header label {
  position: absolute;
  top: 10px;
  z-index: 100;
  cursor: pointer;
}
.overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.301);
  backdrop-filter: blur(10px);
  opacity: 0;
  transform: scale(0);
  transition: opacity 0.4s;
  z-index: 999;
}
header input:checked ~ .overlay {
  transform: scale(1);
  opacity: 1;
}
/* Header */
header {
  width: var(--main-width);
  height: 120px;
  margin: 0 auto;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
header input {
  width: 23px;
  height: 23px;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  position: relative;
}
header input::before {
  content: "\f805";
  position: absolute;
  top: 0;
  left: 0;
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  color: rgb(138, 138, 138);
  font-size: 1.2em;
}
header p {
  font-size: 1.2em;
}
nav {
  position: fixed;
  top: 50%;
  left: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  width: 250px;
  padding-top: 60px;
  background: #181818;
  border-radius: 10px;
  box-shadow: inset 0 0 50px rgba(230, 230, 230, 0.041);
  transform: translate(-50%, -50%) scale(0);
  z-index: 1000;
  padding-bottom: 5px;
  transition: transform 0.4s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  border: 2px solid rgba(255, 255, 255, 0.151);
}
header input:checked::before {
  display: none;
}
header input:checked ~ nav {
  transform: translate(-50%, -50%) scale(1);
}
nav a {
  text-decoration: none;
  font-size: 0.8em;
  margin: 0.5em 0;
}
nav a .linkBtn {
  background: none;
  color: rgba(255, 255, 255, 0.61);
  border: none;
  cursor: pointer;
}
nav::before {
  content: "";
  width: 100%;
  height: 22%;
  position: absolute;
  border-radius: 10px 10px 0 0;
  top: 0;
  background: rgba(255, 255, 255, 0.021);
  box-shadow: inset 0 0 50px rgba(23, 77, 255, 0.041);
  z-index: 20;
}
header label {
  position: absolute;
  top: 10px;
  z-index: 100;
  cursor: pointer;
}
.overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.301);
  backdrop-filter: blur(10px);
  opacity: 0;
  transform: scale(0);
  transition: opacity 0.4s;
  z-index: 999;
}
header input:checked ~ .overlay {
  transform: scale(1);
  opacity: 1;
}
