.projects {
  width: var(--main-width);
  margin: 150px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.projects h2 {
  color: #8e2de2;
}
.projects .container {
  margin-top: 35px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.projectLink {
  text-decoration: none;
  margin: 10px 30px;
}
.projectCard {
  width: 180px;
  height: 200px;
  background: rgba(255, 255, 255, 0.048);
  border-radius: 10px;
  position: relative;
  text-align: center;
  text-decoration: none;
  backdrop-filter: blur(30px);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.114);
  padding: 10px;
}
.projectCard p:nth-child(2) {
  margin-top: 10px;
}
.projectCard p {
  color: var(--sec-color);
  margin: 2px;
  text-decoration: none;
}
.projectCard .imgContainer {
  width: 100%;
  height: 60%;
  border-radius: 10px 10px;
  overflow: hidden;
}
.projectCard .imgContainer img {
  width: 100%;
  height: 100%;
}
