main {
  width: var(--main-width);
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  height: 70vh;
  flex-direction: column;
  position: relative;
}
main p {
  font-size: 0.7em;
  margin: 10px 0;
  color: var(--sec-color);
}
main p:nth-child(3) {
  font-size: 0.9em;
}
main h1 {
  text-align: center;
}
main h1 span {
  font-family: "Dancing Script", cursive;
  font-size: 1.2em;
  color: #8e2de2;
}
.indicator {
  position: absolute;
  bottom: 0;
  width: 25px;
  height: 40px;
  border: 2px solid var(--sec-color);
  border-radius: 50px;
}
.indicator::before {
  content: "";
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%);
  width: 4px;
  height: 4px;
  background: var(--sec-color);
  border-radius: 50px;
  animation: dotAnimation 1s infinite;
}
@keyframes dotAnimation {
  0% {
    transform: translate(-50%, 0%);
  }
  100% {
    transform: translate(-50%, 300%);
  }
}
