.about {
  width: var(--main-width);
  height: 400px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.about p span {
  color: #8e2de2;
  font-family: "Dancing Script", cursive;
  font-size: 1.4em;
}
