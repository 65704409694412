@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
}
:root {
  --main-width: 680px;
  --sec-color: rgba(255, 255, 255, 0.521);
}
@media (max-width: 750px) {
  :root {
    --main-width: 85%;
  }
}
body {
  background: #131313;
  color: white;
}

@media (max-width: 565px) {
  .skills {
    width: 100%;
    margin: 50px auto 100px auto;
  }
  .card {
    width: 80px;
    height: 100px;
    margin: 10px 10px;
  }
  .fa-3x {
    font-size: 2em;
  }
  .card p {
    font-size: 12px;
  }
}
